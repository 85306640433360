import './review-form.styles.scss'


import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Button from '../button/button.component';

import StarRating from '../../components/star rating/star-rating.component';


const ReviewForm= (pageName) => {
  const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_czfjmv5', 'template_yt92a7m', form.current, {
            publicKey: 'tTjO4UU9_aSlHhD9Z',
          })
          .then(
            () => {
              alert("Thank you for the review! 😊")
              console.log('SUCCESS!');
            },
            (error) => {
              alert('Something seems to have gone wrong. Please try again')
              console.log('FAILED...', error.text);
            },
          );
          e.target.reset()
      };
  return (
    <>
    <div className="review-form-container">
        <div className="review-form-text-container">
            <h3 className="review-form-header">Write a review</h3>
            <span className="review-form-subheader">
                You can write your review here.  To combat spam, all reviews are moderated before being published.
            </span>
        </div>
        <form action="" className="review-form" ref={form} onSubmit={sendEmail}>
                <div className="star-rating-section-container">
                <StarRating/>
                </div>
                <textarea className="review-form-input-msg" name={'user_review_message'} required placeholder='Review message...'></textarea><br/>
                <input className="review-form-input" type="text"  name={'user_review_fullname'} placeholder='Name' required/><br/>
                <input className="review-form-input" type="email"  name={'user_review_email'} required placeholder='Email'/><br/>
                <input type="text" className='display-none' name={'page_name'} value={pageName.pageName}/>
                <div className="submit-btn-container">
                    <Button className='submit-btn' buttonSize='btn--large' type="submit">Submit</Button>
                </div>
        </form>
    </div>
    </>
  );
}

export default ReviewForm;

