import KyanWyssImg1 from "../../../assests/advert imgs/kyan wyss/nail-art-5653459_1920.jpg";

import TaiWyssImg1 from "../../../assests/advert imgs/tai wyss/beauty-salon-4043096_1920.jpg";
import TaiWyssImg2 from "../../../assests/advert imgs/tai wyss/estetica-2901113_1920.jpg";
import TaiWyssImg3 from "../../../assests/advert imgs/tai wyss/salon-2561845_1920.jpg";

import CairHandleyImg from "../../../assests/advert imgs/Cair Handley/IMG_5972.jpeg";

import TarynDeKerkImg1 from "../../../assests/advert imgs/Taryn De Kerk/IMG_20190729_1244092.png";
import TarynDeKerkImg2 from "../../../assests/advert imgs/Taryn De Kerk/te084030907.png";
import TarynDeKerkImg3 from "../../../assests/advert imgs/Taryn De Kerk/S23.png";

import CandiceUlyateImg from "../../../assests/advert imgs/Candice Ulyate/IMG-20240930-WA0038.jpg";

import NicoleVanSensieImg from "../../../assests/advert imgs/Nicole Van Sensie/IMG_5872.png";

import TohierahKarriemImg5 from "../../../assests/advert imgs/Tohierah Karriem/FB_IMG_1728296806486.jpg";
import TohierahKarriemImg3 from "../../../assests/advert imgs/Tohierah Karriem/test2.png";
import TohierahKarriemImg4 from "../../../assests/advert imgs/Tohierah Karriem/FB_IMG_1728296838491.png";
import TohierahKarriemImg2 from "../../../assests/advert imgs/Tohierah Karriem/FB_IMG_1728296849313.jpg";
import TohierahKarriemImg6 from "../../../assests/advert imgs/Tohierah Karriem/FB_IMG_1728296858100.png";
import TohierahKarriemImg1 from "../../../assests/advert imgs/Tohierah Karriem/new profile.jpeg";

import JudithFungisaniImg from "../../../assests/advert imgs/Judith Fungisani/e6246822-e1e4-4db3-8bf0-6b514e838d07_Original.jpeg";

import YolanderPintuImg1 from "../../../assests/advert imgs/Yolander Pintu/big-full-body-aroma-therapy-massage-60mins.jpg";
import YolanderPintuImg2 from "../../../assests/advert imgs/Yolander Pintu/1000s_edit_82943382850884.jpg";
import YolanderPintuImg3 from "../../../assests/advert imgs/Yolander Pintu/IMG_20241001_110951_edit_9029626649663.png";

import MilkaCoxImg from "../../../assests/advert imgs/Milka  Cox/IMG_9811.png";

import DoniraPhiriImg1 from "../../../assests/advert imgs/Donira Phiri/1715069996100.png";
import DoniraPhiriImg2 from "../../../assests/advert imgs/Donira Phiri/IMG-20230516-WA0008(1).jpg";
import DoniraPhiriImg3 from "../../../assests/advert imgs/Donira Phiri/IMG-20230516-WA0013.jpg";

import SarahMaungaImg from "../../../assests/advert imgs/Sarah Maunga/IMG-20241018-WA0016.png";

import XolaNdumndumImg1 from "../../../assests/advert imgs/Xola Ndumndum/IMG_3367.png";
import XolaNdumndumImg2 from "../../../assests/advert imgs/Xola Ndumndum/IMG_3368.jpeg";
import XolaNdumndumImg3 from "../../../assests/advert imgs/Xola Ndumndum/IMG_3370.jpeg";
import XolaNdumndumImg4 from "../../../assests/advert imgs/Xola Ndumndum/IMG_3371.png";

import GizzelleBeyersImg1 from "../../../assests/advert imgs/Gizzelle Beyers/Light Beige Elegant Experimental Art Deco Facebook Cover_20240928_161301_0000.png";
import GizzelleBeyersImg2 from "../../../assests/advert imgs/Gizzelle Beyers/IMG-20241002-WA0073.jpg";
import GizzelleBeyersImg3 from "../../../assests/advert imgs/Gizzelle Beyers/IMG-20240920-WA0036.jpg";
import GizzelleBeyersImg4 from "../../../assests/advert imgs/Gizzelle Beyers/IMG-20241003-WA0013.jpg";
import GizzelleBeyersImg5 from "../../../assests/advert imgs/Gizzelle Beyers/IMG-20241004-WA0016 (1).png";

import CandiceBennettImg1 from "../../../assests/advert imgs/Candice Bennett/1728304389809.png";

import MelanieHendricksImg from "../../../assests/advert imgs/Melanie Hendricks/IMG_20240716_121217.png";

import NatashaPImg from "../../../assests/advert imgs/Natasha P/0f24c20e-79e1-43f1-bdb8-0f2cb2b3b673.png";

import JosehineBerylDanielsImg from "../../../assests/advert imgs/Josehine Beryl Daniels/WhatsApp Image 2024-10-31 at 16.47.33.jpeg";

import TeeTishaImg from "../../../assests/advert imgs/Tee Tisha/alan-caishan-cU53ZFBr3lk-unsplash.jpg";

import ThandieBeautyImg from "../../../assests/advert imgs/Thandie Beauty/Screenshot_20220108-173724_061835 (1).png"

import NeliaNBImg1 from "../../../assests/advert imgs/Nelia N B/IMG_20241017_135610.png"
import NeliaNBImg2 from "../../../assests/advert imgs/Nelia N B/IMG_20240930_150757.png"

import LoganMentoImg1 from "../../../assests/advert imgs/Logan Mento/image555.png"
import LoganMentoImg2 from "../../../assests/advert imgs/Logan Mento/image666.png"
import LoganMentoImg3 from "../../../assests/advert imgs/Logan Mento/image777.png"

import AmaliaFeroImg1    from "../../../assests/advert imgs/Amalia Fero/amal.png"

import CarmenVanEedenImg1 from "../../../assests/advert imgs/Carmen  Van Eeden/IMG-20241118-WA0023_727.jpg"

import LovenessMatondoImg1 from "../../../assests/advert imgs/Loveness Matondo/20240526_114727.png"
import LovenessMatondoImg2 from "../../../assests/advert imgs/Loveness Matondo//20241026_151618 (1).png"

import LulekaKetileImg1 from "../../../assests/advert imgs/Luleka Ketile/engin-akyurt-ZbzYDboN7fg-unsplash.jpg"

import RoseGunterImg1 from "../../../assests/advert imgs/Rose Gunter/4387196874.png"

import TonyDEAndradeImg1 from "../../../assests/advert imgs/Tony DE Andrade/ME 10.jpg"
import TonyDEAndradeImg2 from "../../../assests/advert imgs/Tony DE Andrade/ME 58.png"
import TonyDEAndradeImg3 from "../../../assests/advert imgs/Tony DE Andrade/Manscape 1.jpg"
import TonyDEAndradeImg4 from "../../../assests/advert imgs/Tony DE Andrade/Wax 1.png"

import PhindileSibandaImg1 from "../../../assests/advert imgs/Phindile Sibanda/inbound4401456729478902219.jpg"
import PhindileSibandaImg2 from "../../../assests/advert imgs/Phindile Sibanda/inbound4245624012261153208.jpg"





export const KyanWyssAdInfo = 
    {   
        pageHref : '/advert',
        imgSlides: [
            {
                imgSrc: KyanWyssImg1,
                imgAlt: 'KyanWyssImg1',
            }
        ],
        Name: "🖤 Layla Van Zyl 🖤",
        address: "Shop 2/2570 Gold Coast Highway, Mermaid Beach, 4218, Gold Coast",
        servicesInfo: [
            {   serviceType: 'Gel Nails',
                services: ['Gel Colour Nails','Gel Colour Toes','Gel Removal','Gel Ombré',]
            },
            {   serviceType: 'Acrylic Nails',
                services: ['Acrylic Dip with Colour','Acrylic Removal','Acrylic removal from another salon',]
            }
        ],
        about: 'I have been in the industry for 3 years now and thoroughly enjoy making people feel good and confident while doing their nails and with the end results.',
        number: '041 685 42793',
        email: 'layla@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Tuesday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Wednesday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Thursday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Friday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Saturday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Sunday:',
                hours: '8:00 am - 5:00 pm',
            },
        ],
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: '',
        Reviews: [],
    }
export const TaiWyssAdInfo = 
    {
        pageHref : '/advert1',
        imgSlides: [
            {
                imgSrc: TaiWyssImg1,
                imgAlt: 'TaiWyssImg1',
            },
            {
                imgSrc: TaiWyssImg2,
                imgAlt: 'TaiWyssImg2',
            },
            {
                imgSrc: TaiWyssImg3,
                imgAlt: 'TaiWyssImg3',
            },
        ],
        Name: "K&W’s Hair & Nails",
        address: "Shop 2/2570 Gold Coast Highway, Mermaid Beach, 4218, Gold Coast",
        servicesInfo: [
            {   serviceType: 'Gel Nails',
                services: ['Gel Colour Nails','Gel Colour Toes','Gel Removal','Gel Ombré',]
            },
            {   serviceType: 'Acrylic Nails',
                services: ['Acrylic Dip with Colour','Acrylic Removal','Acrylic removal from another salon',]
            }
        ],
        about: 'I have been in the industry for 3 years now and thoroughly enjoy making people feel good and confident while doing their nails and with the end results.',
        number: '041 685 42793',
        email: 'k&w@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Tuesday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Wednesday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Thursday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Friday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Saturday:',
                hours: '8:00 am - 5:00 pm',
            },
            {   day: 'Sunday:',
                hours: '8:00 am - 5:00 pm',
            },
        ],
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: '',
        Reviews: [],
        priceList: [
            {
                priceHeader: 'Aesthetics',
                priceSubSections: [
                    {
                     priceSubSectionHeader: 'Gel Nails',
                     priceSubSectionItems :[
                        {
                            item : 'Gel Colour Nails',
                            cost: 250,
                        },
                        {
                            item : 'Gel Colour Toes',
                            cost: 220,
                        }
                     ]
                    },
                    {
                     priceSubSectionHeader: 'Acrylic Nails',
                     priceSubSectionItems :[
                        {
                            item : 'Acrylic Dip with Colour',
                            cost: 200,
                        },
                        {
                            item : 'Acrylic Removal',
                            cost: 50,
                        }
                     ]
                    },
                    {
                     priceSubSectionHeader: 'Massage',
                     priceSubSectionItems :[
                        {
                            item : '60 min – Swedish deep tissue',
                            cost: 350,
                        },
                        {
                            item : '45 min – Swedish deep tissue',
                            cost: 460,
                        }
                     ]
                    },
                    {
                     priceSubSectionHeader: 'Tinting',
                     priceSubSectionItems :[
                        {
                            item : 'Eyebrow Tint',
                            cost: 80,
                        },
                        {
                            item : 'Eyelash Tint',
                            cost: 70,
                        }
                     ]
                    }
                ]
            },
        ]
        

    }
export const CairHandleyAdInfo = 
    {
        pageHref : '/Cair-Precision-Beauty',
        imgAlt: "Kyan Wyss Img",
        imgSlides: [
            {
                imgSrc: CairHandleyImg,
                imgAlt: 'CairHandleyImg',
            },
        ],
        Name: "Cair - Precision Beauty",
        address: "Precision Beauty 22 Riebeek Street & Long Street Cape Town, WC, 8001",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Therapeutic massage','Swedish massage','Hot stone treatments ','Aromatherapy','Shiatsu','Lymph Drainage']
            },
        ],
        about: 'Relax and recharge with my soothing massage, designed to ease stress and tension from head to toe. In a serene setting with calming music, each session is tailored to your comfort, offering a deeply restorative experience. Whether you need relief from muscle aches or a peaceful escape, my massage is your perfect retreat.',
        number: '076 563 4460',
        email: 'cairhandley@yahoo.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '❌',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27765634460',
        Reviews: [
            {
                reviewName: 'Matthew' ,
                reviewEmail: 'matthewvanderwalt5@gmail.com',
                reviewMsg: `One of the best hot stone massages I have had. Cair made me feel very welcome and I can't find any fault in her services. Well done and see you again soon`,
                reviewRating: 5,
            },
        ],
    }

export const TarynDeKerkAdInfo = 
    {
        pageHref : '/Taryn-Perfect-Touch-Therapy',
        imgSlides: [
            {
                imgSrc: TarynDeKerkImg1,
                imgAlt: 'Taryn de kerk Img1',
            },
            {
                imgSrc: TarynDeKerkImg2,
                imgAlt: 'Taryn de kerk Img2',
            },
            {
                imgSrc: TarynDeKerkImg3,
                imgAlt: 'Taryn de kerk Img3',
            },
        ],
        Name: "Taryn - Perfect Touch Therapy",
        address: "22 Riebieck Street Cape Town , Western Cape , 8001",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Swedish massage','Deep tissue ','Aromatherapy']
            },
        ],
        about: 'Elevate your well-being with therapeutic touch.My massage will leave you balance and relaxed,so come an book a tranquil session with me.',
        number: '082 759 2239',
        email: '',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        websiteHref:'https://massage.co.za/therapist/detailed-profile/id/26132',
        bookHref: 'https://wa.me/27827592239',
        Reviews: [],
    }
    
export const CandiceUlyateAdInfo = 
    {
        pageHref : '/Candice-Ulyate',
        imgSlides: [
            {
                imgSrc: CandiceUlyateImg,
                imgAlt: 'Candice Ulyate Img',
            },
        ],
        Name: "Candice Ulyate",
        address: "Belvedere road , Claremont Cape Town , Western cape , 7708",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Swedish','Deep tissue ','Aromatherapy massage','Exfoliating  body scrubs','Exfoliating facials']
            },
        ],
        about: `"With five years of experience, I offer tailored massage therapy: aromatherapy for relaxation, deep tissue for muscle relief, Swedish for full-body relaxation, and exfoliating scrubs for glowing skin. Let me create a treatment to ease pain and leave you radiant.`,
        number: '081 278 0273',
        email: 'candiceulyate123@gmail.com',  
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27812780273',
        Reviews: [
            {
                reviewName: 'Charlene' ,
                reviewEmail: 'wysscharlene@gmail.com',
                reviewMsg: 'Candice gives one of the best massages in town. So glad I came across this site and found her. Cant wait for my next one.',
                reviewRating: 5,
            },
            {
                reviewName: 'Darryl' ,
                reviewEmail: 'gush.haunch0b@icloud.com',
                reviewMsg: 'Fantastic massage. Full body strong deep tissue. Attention to detail. Definitely worth a try.',
                reviewRating: 5,
            },
        ],
    }
export const NicoleVanSensieAdInfo = 
    {
        pageHref : '/Nicole-Van-sensie',
        imgSlides: [
            {
                imgSrc: NicoleVanSensieImg,
                imgAlt: 'Nicole Van Sensie Img',
            },
        ],
        Name: "Nicole Van sensie",
        address: "130 belvedere road , Claremont Cape town , Western Cape , 7708",
        servicesInfo: [
            {   serviceType: 'Hair',
                services: ['Hair cuts','Color correction','balayage','Highlights','Hair treatments']
            },
        ],
        about: 'I am a qualified hairstylist offering personalized cuts, colors, and styles. I specialize in color correction, balayage, highlights, and revitalizing hair treatments.',
        number: '072 719 4315',
        email: 'Nicolepillay25@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '❌',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27727194315',
        Reviews: [],
    }
export const TohierahKarriemAdInfo = 
    {
        pageHref : '/Tohierah-Karriem',
        imgSlides: [
            {
                imgSrc: TohierahKarriemImg1,
                imgAlt: 'Tohierah Karriem Img1',
            },
            {
                imgSrc: TohierahKarriemImg2,
                imgAlt: 'Tohierah Karriem Img2',
            },
            {
                imgSrc: TohierahKarriemImg3,
                imgAlt: 'Tohierah Karriem Img3',
            },
            {
                imgSrc: TohierahKarriemImg4,
                imgAlt: 'Tohierah Karriem Img4',
            },
            {
                imgSrc: TohierahKarriemImg5,
                imgAlt: 'Tohierah Karriem Img5',
            },
            {
                imgSrc: TohierahKarriemImg6,
                imgAlt: 'Tohierah Karriem Img6',
            },
        ],
        Name: "Tohierah Karriem",
        address: "19 Yorkshire street weltevreden valley Cape town, Western cape, 7785",
        servicesInfo: [
            {   serviceType: 'EyeLashes',
                services: ['Eyelash Art','Volume extensions','Hybrid extentions','Classic extentions','YY & W extentions']
            },
        ],
        about: 'Curvy Beauty launched four months ago after I became certified by Glam and Glow Academy. The positive response has been amazing, and my satisfied clients fuel my passion as I let my work speak for itself. 😊',
        number: '061 424 2650',
        email: 'mog.karriem@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'https://www.instagram.com/tohierah31/',
        twitterHref:'',
        facebookHref:'https://www.facebook.com/profile.php?id=61561842092082',
        bookHref: 'https://wa.me/27614242650',
        Reviews: [],
    }
export const JudithFungisaniAdInfo = 
    {
        pageHref : '/Judith-Fungisani',
        imgSlides: [
            {
                imgSrc: JudithFungisaniImg,
                imgAlt: 'Judith Fungisani Img',
            },
        ],
        Name: "Judith Fungisani",
        address: "10 Induland crescent , Landsdowne Cape Town , Western province, 7779",
        servicesInfo: [
            {   serviceType: 'Hair Removal',
                services: ['Laser hair ',]
            },
        ],
        about: 'I am a somatologist with eight years of experience specializing in laser hair removal. Passionate about aesthetics, I enhance clients’ confidence through personalized, safe, and effective treatments that yield lasting results.',
        number: '076 010 2867',
        email: 'judithfungisani@yahoo.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27760102867',
        Reviews: [],
    }
export const YolanderPintuAdInfo = 
    {
        pageHref : '/Yolander-Pintu',
        imgSlides: [
            {
                imgSrc: YolanderPintuImg1,
                imgAlt: 'Yolander Pintu Img1',
            },
            {
                imgSrc: YolanderPintuImg2,
                imgAlt: 'Yolander Pintu Img2',
            },
            {
                imgSrc: YolanderPintuImg3,
                imgAlt: 'Yolander Pintu Img3',
            },
        ],
        Name: "Yolander Pintu",
        address: "Erf 111 tulbagh , Klipriver park Cape town, Western cape, 6820",
        servicesInfo: [
            {   serviceType: 'Massage Therapist',
                services: ['Swedish','Thai','hotstone','aromatherapy','pregnancy','spot','trigger point','Thai ball','Theurapatic session',]
            },
            {   serviceType: 'Skincare',
                services: ['Facials',]
            },
            {   serviceType: 'Hair Removal',
                services: ['Waxing',]
            },
        ],
        about: 'I have been a massage therapist for 4 years I enjoy my job and I love giving people spa experience in the comfort of their own space.. Our mobile spa offers massages, waxing, facials, man & ped. We specialize in wellness, healing and health care ',
        number: '067 011 0813',
        email: 'www.yemuraip@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'https://www.instagram.com/yandymobilespa/',
        twitterHref:'',
        facebookHref:'Https://facebook.com/yandymobilespa',
        bookHref: 'https://wa.me/27670110813',
        Reviews: [],
    }
export const MilkaCoxAdInfo = 
    {
        pageHref : '/Milka-Cox',
        imgSlides: [
            {
                imgSrc: MilkaCoxImg,
                imgAlt: 'Milka Cox Img',
            },
       ],
        Name: "Milka Cox",
        address: "22 Riebeek street Cape town , Western cape , 8001",
        servicesInfo: [
            {   serviceType: 'Massages ',
                services: ['Aromatherapy ','Deep tissue ','Swedish ','Pregnancy ',]
            },
            {   serviceType: 'Nails',
                services: ['Nail art ','Mani and Pedi',]
            },
            {   serviceType: 'Hair Services',
                services: ['Waxing full body','Lip and brow threading','Laser hair removal ','microblading','Hair styling']
            },
            {   serviceType: 'Skin Care',
                services: ['Facials',]
            },
        ],
        about: 'As a multi-talented beauty expert, I have been in the industry  for 3 years. I offer a range of services to enhance overall well-being aesthetic. From hair styling and makeup artistry to massage therapy, eyelash extensions, skin care and nail technician services. ',
        number: '061 919 4401',
        email: 'info@milkas.co.za',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        websiteHref:'https://milkas.co.za/',
        bookHref: 'https://www.fresha.com/a/milkas-precission-cape-town-22-riebeek-street-gmucg0nl/booking?menu=true',
        Reviews: [],
    }
export const DoniraPhiriAdInfo = 
    {
        pageHref : '/Donira-Phiri',
        imgSlides: [
            {
                imgSrc: DoniraPhiriImg1,
                imgAlt: 'Donira Phiri Img1',
            },
            {
                imgSrc: DoniraPhiriImg2,
                imgAlt: 'Donira Phiri Img2',
            },
            {
                imgSrc: DoniraPhiriImg3,
                imgAlt: 'Donira Phiri Img3',
            },
       ],
        Name: "Donira Phiri",
        address: "Century city , N bank Ln Cape town, Western Cape , 7441",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Hot stone','Deep tissue ','Swedish','sports ',]
            },
            {   serviceType: 'Nails',
                services: ['gel applications ','Mani and Pedi',]
            },
        ],
        about: 'I’ve been a massage therapist for three years, and I love being part of this dynamic industry in the bustling Mother City of Cape Town. My passion is helping clients feel relaxed, healed, and renewed. For those who can’t find time to visit a spa, I bring the spa experience directly to them, creating a space of tranquility and rejuvenation wherever they are. 😌🧘',
        number: '060 524 5344',
        email: 'nirapraise@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'https://www.instagram.com/azuli.beauty/',
        twitterHref:'',
        facebookHref:'https://www.facebook.com/profile.php?id=61564944994250',
        bookHref: 'https://wa.me/27605245344',
        Reviews: [],
    }
export const SarahMaungaAdInfo = 
    {
        pageHref : '/Sarah-Maunga',
        imgSlides: [
            {
                imgSrc: SarahMaungaImg,
                imgAlt: 'Sarah Maunga Img1',
            },
       ],
        Name: "Sarah Maunga",
        address: "22 Riebeek street , Cape Town , Western cape , 8001",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Hot stone','Deep tissue ','Swedish','Aromatherapy ',]
            },
        ],
        about: 'As a massage therapist with one year of experience, I offer a variety of signature treatments, including essential oil-infused aromatherapy, soothing Swedish strokes, targeted deep tissue relief, and warm hot stone therapy. Each session is tailored to help you unwind and discover serenity and relaxation.',
        number: '069 279 6323',
        email: 'sarahmaunga188@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27692796323',
        Reviews: [],
    }
export const XolaNdumndumAdInfo = 
    {
        pageHref : '/Xola-Ndumndum',
        imgSlides: [
            {
                imgSrc: XolaNdumndumImg1,
                imgAlt: 'Xola Ndumndum Img1',
            },
            {
                imgSrc: XolaNdumndumImg2,
                imgAlt: 'Xola Ndumndum Img2',
            },
            {
                imgSrc: XolaNdumndumImg3,
                imgAlt: 'Xola Ndumndum Img3',
            },
            {
                imgSrc: XolaNdumndumImg4,
                imgAlt: 'Xola Ndumndum Img4',
            },
       ],
        Name: "Xola Ndumndum",
        address: "Sea point,Cape Town , Western Cape , 8001",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Hot stone','Deep tissue ','Swedish','Aromatherapy ','Trigger points','Reflexology']
            },
        ],
        about: 'With four years of experience, I provide mobile massage services tailored to your needs for balance and relaxation. I come fully equipped with a massage bed, towels, soothing music, oils, and hot stones to create a serene experience in your space.',
        number: '074 635 5970',
        email: 'kneadedhopemobilespa@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'https://www.instagram.com/kneaded_hope_mobile_spa/',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27746355970',
        Reviews: [
            {
                reviewName: 'Brian' ,
                reviewEmail: 'brian@phoenixrisk.co.za',
                reviewMsg: `I hired Xolo for her stunning 90 minute hot stones massage. This treatment really helped my back especially as I suffer from severe back pain.`,
                reviewRating: 5,
            },
        ],
    }
export const GizzelleBeyersAdInfo = 
    {
        pageHref : '/Gizzelle-Beyers',
        imgSlides: [
            {
                imgSrc: GizzelleBeyersImg1,
                imgAlt: 'Gizzelle Beyers Img1',
            },
            {
                imgSrc: GizzelleBeyersImg2,
                imgAlt: 'Gizzelle Beyers Img2',
            },
            {
                imgSrc: GizzelleBeyersImg3,
                imgAlt: 'Gizzelle Beyers Img3',
            },
            {
                imgSrc: GizzelleBeyersImg4,
                imgAlt: 'Gizzelle Beyers Img4',
            },
            {
                imgSrc: GizzelleBeyersImg5,
                imgAlt: 'Gizzelle Beyers Img5',
            },
       ],
        Name: "Gizzelle Beyers",
        address: "85 9th Avenue, Kensington, Cape Town, Western Cape , 7405",
        servicesInfo: [
            {   serviceType: 'Eyelashes',
                services: ['Lash removal','Add color','Lash bath','Volume',' Hybrid','Classic']
            },
            {   serviceType: 'Hair Removal',
                services: ['Brow waxing','Lip Waxing',]
            },
            {   serviceType: 'Nails',
                services: ['Art','Gel','Repairs','Mani & Pedi','Acrylic','Polygel']
            },
            {   serviceType: 'Skincare',
                services: ['Callus Remover',]
            },
        ],
        about: 'With over 10 years in the beauty industry, I specialize in nails and lashes at my home-based salon. I’m passionate about making clients feel their best, offering a warm and relaxing experience.',
        number: '079 545 1155',
        email: 'mustbegizzelle@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '❌',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        websiteHref:'https://www.fresha.com/a/must-be-gizzelle-beauty-studio-cape-town-85-9th-avenue-ss2v4zcv',
        bookHref: 'https://www.fresha.com/a/must-be-gizzelle-cape-town-85-9th-avenue-y7z8h6qj/all-offer?venue=true',
        Reviews: [],
    }
export const CandiceBennettAdInfo = 
    {
        pageHref : '/Candice-Bennett',
        imgSlides: [
            {
                imgSrc: CandiceBennettImg1,
                imgAlt: 'Candice Bennett Img1',
            },
       ],
        Name: "Candice Bennett",
        address: "I do call-outs in Cape Town's city center and surrounding areas, including Woodstock, Sea Point, Green Point, and more.",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Holistic',]
            },
        ],
        about: 'I have been in the industry for 10years + I have a passion for massage and for pampering my clients 🙂',
        number: '063 315 2494',
        email: 'cndcbnntt@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27633152494',
        Reviews: [],
    }
export const MelanieHendricksAdInfo = 
    {
        pageHref : '/Melanie-Hendricks',
        imgSlides: [
            {
                imgSrc: MelanieHendricksImg,
                imgAlt: 'Melanie Hendricks Img',
            },
       ],
        Name: "Melanie Hendricks",
        address: "130 belvedere road, Claremont Cape town, Western cape, 7700",
        servicesInfo: [
            {   serviceType: 'Eyelash extensions',
                services: ['Mink','Synthetic','Classic','hybrid','Mega','volume',]
            },
        ],
        about: `I specialize in individual lash extensions, offering mink and synthetic options in classic, volume, hybrid, and mega styles. I've been lashing since 2010 and use only the highest-quality products.`,
        number: '071 019 8875',
        email: 'melanie.vester27@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27710198875',
        Reviews: [],
    }
export const NatashaPAdInfo = 
    {
        pageHref : '/Natasha-P',
        imgSlides: [
            {
                imgSrc: NatashaPImg,
                imgAlt: 'Natasha P Img',
            },
       ],
        Name: "Natasha P",
        address: "Belvedere road, Claremont, Cape Town, 7708",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Swedish','Deep tissue','Aromatherapy','Reflexology','Indian head massage',]
            },
        ],
        about: `With five years of experience as a massage therapist, I offer a range of techniques, including Swedish, Deep Tissue, Aromatherapy, Reflexology, and Indian Head Massage. My goal is to provide a tailored, relaxing experience that supports your wellness and rejuvenation.`,
        number: '060 227 4576',
        email: 'pattersonn755@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27602274576',
        Reviews: [
            {
                reviewName: 'Michael' ,
                reviewEmail: 'hoffmanmichael201@gmail.com',
                reviewMsg: `Had a brilliant deep tissue massage from Tash. Feel a lot of better now and trust me I will be back! Professional and well mannered. Thanks Back2Beauty for helping me find her`,
                reviewRating: 5,
            },
        ],
    }
export const JosehineBerylDanielsAdInfo = 
    {
        pageHref : '/Josehine-Beryl-Daniels',
        imgSlides: [
            {
                imgSrc: JosehineBerylDanielsImg,
                imgAlt: 'Josehine Beryl Daniels Img',
            },
       ],
        Name: "Josehine Beryl Daniels",
        address: "20 Burnley road Crawford , Western Cape , Cape town",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Reflexologist','Thai','Ashiatsu','Shiatsu','Deep tissue','Sport','Sinus drainage','Migraine Treatment','Signature massage']
            },
        ],
        about: `With over five years of experience as a massage therapist, I specialize in Reflexology, Thai, Ashiatsu, and Shiatsu massage. My goal is to provide each client with a tailored, therapeutic experience that promotes deep relaxation, balance, and overall well-being.`,
        number: '083 678 2676',
        email: 'josiestherapy@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ❌ `,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27836782676',
        Reviews: [],
    }
export const TeeTishaAdInfo = 
    {
        pageHref : '/Tee-Tisha',
        imgSlides: [
            {
                imgSrc: TeeTishaImg,
                imgAlt: 'Tee Tisha Img',
            },
       ],
        Name: "Tee Tisha",
        address: "20 Burnley road Crawford cape town , western cape, 7945",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Swedish','deep tissue',]
            },
        ],
        about: `With three years of experience in massage therapy, I enjoy making clients happy through tailored Swedish and deep tissue massages that promote relaxation and well-being.`,
        number: '068 111 5276',
        email: 'ftiaanah@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ❌ `,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27681115276',
        Reviews: [],
    }
export const ThandieBeautyAdInfo = 
    {
        pageHref : '/Thandie-Beauty',
        imgSlides: [
            {
                imgSrc: ThandieBeautyImg,
                imgAlt: 'Thandie Beauty Img',
            },
       ],
        Name: "Thandie Beauty",
        address: "20 Burnley road Crawford cape town , western cape, 7945",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Swedish','deep tissue',]
            },
            {   serviceType: 'Nails',
                services: ['Pedicure','manicure',]
            },
        ],
        about: `With seven years of experience as a massage therapist, I enjoy helping clients feel their best. I offer Swedish massage, deep tissue, aromatherapy, sports massage, foot reflexology, and also provide manicures and pedicures, all tailored to enhance relaxation and well-being.`,
        number: '067 438 4610',
        email: 'athandilethandi9@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ✔️ 10km`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27674384610',
        Reviews: [],
    }
export const NeliaNBAdInfo = 
    {
        pageHref : '/Nelia-N-B',
        imgSlides: [
            {
                imgSrc: NeliaNBImg1,
                imgAlt: 'Nelia N B Img1',
            },
            {
                imgSrc: NeliaNBImg2,
                imgAlt: 'Nelia N B Img2',
            },
       ],
        Name: "Nelia N B",
        address: "78 Ottery Road , Wynberg, Cape Town, Western Cape , 7800",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Chinese Foot','Sports Massage ','Therapeutic','Deep Tissue','Swedish','Aromatherapy']
            },
            {   serviceType: 'Skincare',
                services: ['Back scrub','Foot scrub',]
            },
        ],
        about: `As an experienced massage therapist, I provide relaxation, relief, and rejuvenation through various therapeutic techniques. Specializing in Chinese, Swedish, deep tissue, and sports massage, I personalize each session to meet clients’ unique needs. My serene environment helps clients unwind, heal, and restore balance in body, mind, and spirit.`,
        number: '071 232 0085',
        email: 'oasisprimrose@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        websiteHref:'',
        bookHref: 'https://wa.me/27712320085',
        Reviews: [],
    }
export const LoganMentoAdInfo = 
    {
        pageHref : '/Logan-Mento',
        imgSlides: [
            {
                imgSrc: LoganMentoImg1,
                imgAlt: 'Logan Mento Img1',
            },
            {
                imgSrc: LoganMentoImg2,
                imgAlt: 'Logan Mento Img2',
            },
            {
                imgSrc: LoganMentoImg3,
                imgAlt: 'Logan Mento Img3',
            },
       ],
        Name: "Logan Mento",
        address: "25 Maho Bch Cres, Macassar, Cape Town, 7130",
        servicesInfo: [
            {   serviceType: 'Nails',
                services: ['Gel overly','Gel Extentions','Soak offs',]
            },
            {   serviceType: 'Hair',
                services: ['Wash','Blow','Flat iron']
            },
        ],
        about: `I've been working with hair and nails since the young age of 12, and over the years, I've developed a true passion for helping women feel comfortable and confident in their own skin.`,
        number: '074 631 6241',
        email: 'loganmento01@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'https://www.instagram.com/blooms_hair_and_nails/',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27746316241',
        Reviews: [
        ],
    }
export const AmaliaFeroAdInfo = 
    {
        pageHref : '/Amalia-Fero',
        imgSlides: [
            {
                imgSrc: AmaliaFeroImg1,
                imgAlt: 'Amalia Fero Img1',
            },
       ],
        Name: "Amalia Fero",
        address: "Wynberg Capetown , Western Cape , 2900",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Full body Swedish massage with oils',]
            },
        ],
        about: `I have dedicated three years to the beauty industry with a focus on delivering a deeply satisfying experience for every client, ensuring they leave each treatment feeling refreshed and revitalized.`,
        number: '069 196 4932',
        email: 'bfero805@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️ (0 - 50km from Wynberg)`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27691964932',
        Reviews: [
            {
                reviewName: 'Brian' ,
                reviewEmail: 'brian@phoenixrisk.co.za',
                reviewMsg: `Amalia gave a blissful 60 minute soft hand massage. I really enjoyed her treatment and will gladly hire her again.`,
                reviewRating: 5,
            },
        ],
    }
export const CarmenVanEedenAdInfo = 
    {
        pageHref : '/Carmen-Van-Eeden',
        imgSlides: [
            {
                imgSrc: CarmenVanEedenImg1,
                imgAlt: 'Carmen Van Eeden Img1',
            },
       ],
        Name: "Carmen Van Eeden",
        address: "Call-outs only : City Bowl | Constantia | Muizenberg",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Deep tissue','Swedish','Sports','Physiotherapy',]
            },
        ],
        about: `I'm Carmen! As a passionate massage therapist, I provide personalized treatments in Deep Tissue, Swedish, and Sports Massage. I also offer innovative physiotherapy sessions without equipment, tailored to your unique needs. Let's chat about how I can help you relax, rejuvenate, and feel your best!`,
        number: '062 366 3160',
        email: '',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27623663160',
        Reviews: [
        ],
    }
export const LovenessMatondoAdInfo = 
    {
        pageHref : '/Loveness-Matondo',
        imgSlides: [
            {
                imgSrc: LovenessMatondoImg1,
                imgAlt: 'Loveness Matondo Img1',
            },
            {
                imgSrc: LovenessMatondoImg2,
                imgAlt: 'Loveness Matondo Img2',
            },
       ],
        Name: "Loveness Matondo",
        address: "Unit 1,Soet Emporium , 30 Oxford Street Durbanville, Cape Town, Western Cape , 7441",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Deep tissue',]
            },
            {   serviceType: 'Nails',
                services: ['Gel','Acrylic','Manicure','Pedicure',]
            },
            {   serviceType: 'Eyelashes',
                services: ['Reach out for treatments']
            },
        ],
        about: `I have been proudly working in the beauty industry for nearly four years, and I can genuinely say I absolutely love what I do. Every aspect of my work brings me joy, but what truly fuels my passion is helping others feel confident, beautiful, and empowered.`,
        number: '084 959 4438',
        email: 'lovenessmatondo683@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '❌',
            },
        ],
        callOuts: `Call Outs: ❌`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27849594438',
        Reviews: [
            {
                reviewName: 'Kayce' ,
                reviewEmail: 'kaycee2804@gmail.com',
                reviewMsg: `Feeling relaxed and rejuvenated after my massage and nail treatment with Loveness. Her attention to detail is impressive!`,
                reviewRating: 5,
            },
        ],
    }
export const LulekaKetileAdInfo = 
    {
        pageHref : '/Luleka-Ketile',
        imgSlides: [
            {
                imgSrc: LulekaKetileImg1,
                imgAlt: 'Luleka Ketile Img1',
            },
       ],
        Name: "Luleka Ketile",
        address: "18 Jim Fouche Plattekloof Cape Town, Western cape , 7941",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Aromatherapy','Deep tissue','Swedish','Sports','Thai',]
            },
            {   serviceType: 'Nails',
                services: ['Express Mani & Pedi','Full Mani & Pedi',]
            },
        ],
        about: `
Experience ultimate relaxation with a skilled massage therapist offering five types of rejuvenating massages: Aromatherapy, Deep Tissue, Sports, Swedish, and Thai. Enhance your pampering session with an Express Mani or Pedi. Escape to bliss today!
        `,
        number: '078 033 8768',
        email: 'rketile@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '❌',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        bookHref: 'https://wa.me/27780338768',
        Reviews: [
            // {
            //     reviewName: 'Kayce' ,
            //     reviewEmail: 'kaycee2804@gmail.com',
            //     reviewMsg: `Feeling relaxed and rejuvenated after my massage and nail treatment with Loveness. Her attention to detail is impressive!`,
            //     reviewRating: 5,
            // },
        ],
    }
    export const RoseGunterAdInfo = 
    {
        pageHref : '/Rose-Gunter',
        imgSlides: [
            {
                imgSrc: RoseGunterImg1,
                imgAlt: 'Rose Gunter Img1',
            },
       ],
        Name: "Rose Gunter",
        address: "Cnr of Tokai Main road and, Dam St, Tokai, Cape Town, Western Cape , 7945",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Therapeutic','Deep tissue','Swedish','Sports','Hot Stone',]
            },
            {   serviceType: 'Package deals available',
                services: ['Includes free body scrubs and hot towel treatments',]
            },
        ],
        about: `Experience personalized massages tailored to your needs, including therapeutic, deep tissue, sports, relaxation, and Swedish techniques. Let Rose's skilled, intuitive touch help you unwind, restore, and feel your best. Book now for a journey to relaxation and healing!`,
        number: '072 012 9322',
        email: 'perfectpaws700@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️ (0-50km from Tokai)`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        websiteHref:'https://massage.co.za/therapist/detailed-profile/id/31911',
        bookHref: 'https://wa.me/27720129322',
        Reviews: [
            // {
            //     reviewName: 'Kayce' ,
            //     reviewEmail: 'kaycee2804@gmail.com',
            //     reviewMsg: `Feeling relaxed and rejuvenated after my massage and nail treatment with Loveness. Her attention to detail is impressive!`,
            //     reviewRating: 5,
            // },
        ],
    }
    export const TonyDEAndradeAdInfo = 
    {
        pageHref : '/Tony-DE-Andrade',
        imgSlides: [
            {
                imgSrc: TonyDEAndradeImg1,
                imgAlt: 'Tony DE Andrade Img1',
            },
            {
                imgSrc: TonyDEAndradeImg2,
                imgAlt: 'Tony DE Andrade Img2',
            },
            {
                imgSrc: TonyDEAndradeImg3,
                imgAlt: 'Tony DE Andrade Img3',
            },
            {
                imgSrc: TonyDEAndradeImg4,
                imgAlt: 'Tony DE Andrade Img4',
            },
        ],
        Name: "Tony DE Andrade",
        address: "Unit 4 Royal Crest (Townhouse) Percussion Street ,Radiokop, Gauteng, 1724",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Reflexology','Deep tissue','Swedish','Sports','Cupping','Body Scrubs']
            },
            {   serviceType: 'Hair Removal',
                services: ['Male waxing','Manscaping',]
            },
            {   serviceType: 'Other',
                services: ['Reiki & spiritual healing','Spiritual guidance from a born healer']
            },

        ],
        about: `I've been running my business since 2017, driven by a passion for delivering exceptional results and exceeding my clients' expectations.`,
        number: '074 999 8089',
        email: 'tony.deandrade@yahoo.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️ (0-25km from Roodepoort)`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'',
        websiteHref:'https://massageexpert.co.za/',
        bookHref: 'https://wa.me/27749998089',
        Reviews: [
            // {
            //     reviewName: 'Kayce' ,
            //     reviewEmail: 'kaycee2804@gmail.com',
            //     reviewMsg: `Feeling relaxed and rejuvenated after my massage and nail treatment with Loveness. Her attention to detail is impressive!`,
            //     reviewRating: 5,
            // },
        ],
    }
    export const PhindileSibandaAdInfo = 
    {
        pageHref : '/Phindile-Sibanda',
        imgSlides: [
            {
                imgSrc: PhindileSibandaImg1,
                imgAlt: 'Phindile Sibanda Img1',
            },
            {
                imgSrc: PhindileSibandaImg2,
                imgAlt: 'Phindile Sibanda Img2',
            },
       ],
        Name: "Phindile Sibanda",
        address: "Middle road morningside and planet fitness morningside, 23 Middle , Johannesburg , 2195",
        servicesInfo: [
            {   serviceType: 'Massages',
                services: ['Swedish','Mobility massage','Myofascial decompression release','Hotstone',]
            },
            {   serviceType: 'Other',
                services: ['I sell beauty and healing products',]
            },

        ],
        about: `I’ve spent 3 years in the beauty industry, perfecting nails, massages, and eyelashes to make clients feel their best.`,
        number: '084 552 0573',
        email: 'phindilesibanda2000@gmail.com',
        workHours: [
            {   day: 'Monday:',
                hours: '✔️',
            },
            {   day: 'Tuesday:',
                hours: '✔️',
            },
            {   day: 'Wednesday:',
                hours: '✔️',
            },
            {   day: 'Thursday:',
                hours: '✔️',
            },
            {   day: 'Friday:',
                hours: '✔️',
            },
            {   day: 'Saturday:',
                hours: '✔️',
            },
            {   day: 'Sunday:',
                hours: '✔️',
            },
        ],
        callOuts: `Call Outs: ✔️ (0-100km from Sandton)`,
        instaHref:'',
        twitterHref:'',
        facebookHref:'https://www.facebook.com/miragespasa',
        websiteHref:'',
        bookHref: 'https://wa.me/27845520573',
        Reviews: [
            // {
            //     reviewName: 'Kayce' ,
            //     reviewEmail: 'kaycee2804@gmail.com',
            //     reviewMsg: `Feeling relaxed and rejuvenated after my massage and nail treatment with Loveness. Her attention to detail is impressive!`,
            //     reviewRating: 5,
            // },
        ],
    }


    