import ReviewCard from '../review Card/review-card.component';
import './review-display.styles.scss';

const ReviewDisplay = (pageReviews, idx) => {
  const reviewsArr = pageReviews.pageReviews
  return (
    <div className='review-display-container'>
      <ul className={reviewsArr.length === 0 ? "display-none" : 'review-display-list-container'}>
        {
          reviewsArr.map((review)=><ReviewCard key={`review ${idx} ${review.reviewName}`} reviewsArr={review}/>)
        }
      </ul>
      <h3 className={reviewsArr.length === 0 ? "review-display-header" : 'display-none'}>0 Reviews so far...</h3>
    </div>
  )
}

export default ReviewDisplay;
