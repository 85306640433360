import React from 'react'
import Accordion from '../../components/accordion/accordion.component'
import './faq-section.styles.scss';

const FAQSection = () => {
  return (
    <div className="faq-section-container">
        <h2 className="faq-section-headline">Frequently asked questions</h2>
        <div className="accordions-container">
            <Accordion  data={data1} />
        </div>
    </div>
  )
}

const data1 = [
    
    {
        key:'accordion 1-1',
        question: 'How can I contact BACK2BEAUTY ADVERTISING (Pty) Ltd?',
        anwser: 'You can reach us via email at support@back2beauty.co.za or by phone at 0750756112'
    },
    {
        key:'accordion 1-3',
        question: 'How can I advertise on BACK2BEAUTY ADVERTISING (Pty) Ltd?',
        anwser: 'To advertise with us, please fill out the Sign up Form on our website. We will contact you and provide a application form which will include details and images required to advertise on the site.'
    },
    {
        key:'accordion 1-4',
        question: 'What types of advertisements do you offer?',
        anwser: `We offer advertisements for an person or business in the beauty and wellness industry. Each individual or business will have their own profile with contact details, services rendered and images.`
    },
    {
        key:'accordion 2-1',
        question: 'Can I update or cancel my advertisement?',
        anwser: 'Yes, you can request cancellations by contacting us at support@back2beauty.co.za and update your profile using the link provided on sign up.If you no longer have access to the profile update link please let us know via email at support@back2beauty.co.za'
    },
    {
        key:'accordion 2-2',
        question: 'Can I request to have my personal data deleted?',
        anwser: 'Yes, you can request the deletion of your personal data by contacting us at support@back2beauty.co.za. We will process your request in accordance with our Privacy Policy and applicable laws.'
    },
    {
        key:'accordion 2-3',
        question: 'How is my personal information protected?',
        anwser: `We take your privacy seriously. Our Privacy Policy outlines how we collect, use, and protect your personal information. You can review it by clicking the link at the bottom of the page labeled Privacy Policy'.`
    },
]

export default FAQSection;
