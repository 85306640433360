import './review-card.styles.scss'
import { FaStar } from 'react-icons/fa'
import reviewIcon1 from '../../assests/review icons/blurry-gradient-haikei.svg'
import reviewIcon2 from '../../assests/review icons/blurry-gradient-haikei2.svg'
import reviewIcon3 from '../../assests/review icons/blurry-gradient-haikei3.svg'
import reviewIcon4 from '../../assests/review icons/blurry-gradient-haikei4.svg'
import reviewIcon5 from '../../assests/review icons/blurry-gradient-haikei5.svg'
import reviewIcon6 from '../../assests/review icons/blurry-gradient-haikei6.svg'
import reviewIcon7 from '../../assests/review icons/blurry-gradient-haikei7.svg'

const ReviewCard = (reviewsArr) => {
    const review = reviewsArr.reviewsArr
    const reviewName = reviewsArr.reviewsArr.reviewName
    const reviewEmail = reviewsArr.reviewsArr.reviewEmail
    const reviewMsg = reviewsArr.reviewsArr.reviewMsg
    const reviewRating = reviewsArr.reviewsArr.reviewRating
    const reviewImgArr = [reviewIcon1,reviewIcon2,reviewIcon3,reviewIcon4,reviewIcon5,reviewIcon6,reviewIcon7]

    const shuffle = (array) => {

      for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          let temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
  }

  return (
    <div className='review-card-container'>
        <div className="review-card-icon-container">
            <img src={shuffle(reviewImgArr)[0]} alt="reviewIcon1" className='review-card-icon-img'/>
        </div>
        <div className="review-card-text-container">
            <h4 className='review-card-text-headline'>{reviewName}</h4>
            <h6 className="review-card-text-subheadline">{reviewEmail}</h6>
            <p className='review-card-text'>{reviewMsg}</p>
            <div className="review-card-stars-container">
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            </div>
        </div>
    </div>
  )
}

export default ReviewCard
