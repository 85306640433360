import { useSelectedServiceUpdate, useServiceOptionUpdate } from '../../context/ad-preview-filters-context';
import './service-preview.styles.scss';

const ServicePreview = (props) => {
    const {Imgs} = props;
    const selectedServiceChange = useSelectedServiceUpdate()
    const ServiceOptionChange = useServiceOptionUpdate()

    return (
      <div className="service-preview-container">
      {Imgs.map((item ,idx )=> {
        return (
          <a href='#discover' onClick={(e) => {
            selectedServiceChange(item.alt)
            ServiceOptionChange(item.alt)
          }}>
            <div className="img-container" key={idx}>
              <h3 className="service-preview-name">{item.title}</h3>

              <img 
                src={item.imgSrc} 
                alt={item.alt} 
                className="img"
                />
            </div>
          </a>
        );
      })}
      </div>
    )
  }

export default ServicePreview;
